import logo from './icon.jpg';
import down from './down.png';
import qrcode from './qrcode.jpg';
import appstoreQRCode from './appstore-qrcode.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{marginTop: 100, color: "#000000"}}>
          有需则请，知能而做。
        </p>
        <img src={down} style={{height: 100, width: 100, paddingTop: 100, marginBottom: 0}}/>
      </header>
      <div>“请做”已上架苹果App Store及各大手机应用市场</div>
      <img src={appstoreQRCode} style={{height: 100, width: 100, marginTop: 20}} />
      <div>App Store</div>
      <footer style={{backgroundColor: "#282c34", color: "#FFFFFF"}}>
        <p style={{fontWeight: "bold", paddingTop: 20}}>
          联系我们
        </p>
        <div style={{marginTop: 30, marginBottom: 30, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <div style={{marginRight: 0}}>
            <div style={{marginBottom: 5}}>微信公众号</div>
            <img src={qrcode} style={{height: 100, width: 100}}/>
          </div>
          <div style={{marginLeft: 0, marginTop: 10}}>
            <div>邮箱</div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>support@mail.popintime.com</div>
          </div>
        </div>
        <div style={{marginTop: 20}}><a style={{color: '#FFFFFF'}} href="https://beian.miit.gov.cn/">浙ICP备2021020876号</a></div>
        <div>增值电信业务许可证浙B2-20210789</div>
        <div style={{paddingBottom: 10}}>©2021-2022 杭州博缤互联网科技有限公司</div>
      </footer>
    </div>
  );
}

export default App;
